<template>
  <div class="content">
    <sub-title>공지사항</sub-title>
    <div class="notice">
      <div class="notice_list" v-for="(n,index) in noticeList" :key="index">
        <div class="n">
          <img src="../../../assets/images/icon/common/notice.png" style="vertical-align: middle">
        </div>
        <div class="t" :style="{'color':n.titleColor}" @click="showContent(n.id)">
          {{ n.title }}
        </div>
        <div class="c" style="text-align: center;padding: 30px 10px 10px 10px"
             v-if="n.contentShow && null != n.eventImg && (n.eventImg.indexOf('yew')!=-1 || n.eventImg.indexOf('yew2')!=-1)">
          <img v-if="null != n.eventImg" :src="n.eventImg" alt="공지사항">
        </div>
        <div class="c" style=";padding: 30px 10px 10px 10px" v-if="n.contentShow && null != n.content">
          <div v-if="null != n.content" v-html="n.content"
               style="box-sizing: border-box;padding: 20px;cursor: pointer"></div>
        </div>
      </div>
    </div>
    <my-unchecked-message-comp></my-unchecked-message-comp>

  </div>

</template>


<script>

import {getNoticeList} from "@/network/userRequest";
import {checkLoginOnCreateMinxin, postionMixin} from "@/common/mixin";
import sportsConst from "@/common/sportsConst";
import SubTitle from "@/views/afront/zero/SubTitle.vue";
import MyUncheckedMessageComp from "@/views/afront/notice/MyUncheckedMessageComp.vue";

export default {
  name: "Notice",
  mixins: [postionMixin, checkLoginOnCreateMinxin],
  components: {MyUncheckedMessageComp, SubTitle},
  data() {
    return {
      sportsConst,
      noticeList: [],
      clickNumber: 0,
      position: "공지사항",
    }
  },
  methods: {
    showContent(id) {
      if (id === this.clickNumber) {
        this.clickNumber = 0
      } else {
        this.clickNumber = id;
      }
      this.noticeList.map(item => {
        if (item.id == id) {
          this.$set(item, 'contentShow', !item.contentShow)
        } else {
          this.$set(item, 'contentShow', false)
        }

      })

    }
  },
  created() {
    this.clickNumber = this.$route.query.id
    /*공지사항추출*/
    getNoticeList(100).then(res => {
      if (res.data.success) {
        this.noticeList = res.data.data
        this.noticeList.map(item => {
          if (item.id == this.clickNumber) {
            this.$set(item, 'contentShow', true)
          } else {
            this.$set(item, 'contentShow', false)
          }
        })
      }
    })
  }
}
</script>

<style scoped>
@import url("../../../assets/css/front/afrontnotice.css");


</style>